{
    "general": {
        "submit": "Conferma",
        "imei": "IMEI",
        "powered-by": "Supportato da",
        "access_level": "Livello di accesso",
        "managed_by": "Gestito da {value}",
        "clear-screen": "Pulisci schermo",
        "reconnect": "Riconnettiti",
        "general": "Generali",
        "alerts": "Allerte",
        "sim_card": "Carta SIM",
        "rl-automotive": "RL Automotive",
        "serial_number": "Numero di serie",
        "you": "Tu",
        "remote": "Remoto",
        "contacts": "Contatti",
        "address": "Indirizzo",
        "readings": "Nessuna Lettura | {conteggio} Lettura | {conteggio} Lettura",
        "provider": "Fornitore",
        "show_more": "Mostra di più",
        "next": "successivo",
        "previous": "precedente",
        "checklist_faults": "{ conteggio } Guasto identificato nella lista di controllo | { conteggio } Difetti identificati nella lista di controllo",
        "show_less": "Mostra di meno",
        "checksum_calculator": "Calcolatore di somma di controllo",
        "checksum_calculator_prompt": "Aggiungere un sensore ad un veicolo? Utilizza il {0}",
        "date": "Data",
        "role": "Ruolo",
        "sort_by": "Ordinara per",
        "alert_low_pressure": "Critica",
        "alert_high_temperature": "Allerta (Alta Temperatura)",
        "warning": "Avviso",
        "warnings": "Nessun Avviso | {conteggio} Avviso | {conteggio} Avvisi",
        "maintenance": "Manutenzione",
        "unassigned": "Non assegnato",
        "stale": "In stallo",
        "print": "Stampare",
        "update_profile": "Aggiorna profilo",
        "signed_in_as": "Registrato come",
        "vin": "VIN",
        "all": "Tutti",
        "no_alert": "OK",
        "sensor_id": "ID Sensore",
        "telematics_units": "Unita Telematiche",
        "start": "Inizio",
        "all_time": "Tempo complessivo",
        "last_seen": "Data ultimo dato",
        "confirm": "Conferma",
        "create": "Crea",
        "from": "Da",
        "admin": "Amministratore",
        "member": "Membro",
        "last_time_seen": "Ultima visualizzazione",
        "last_time_seen_desc": "Ultima visualizzazione (decrescente)",
        "include_inactive": "Includi inattivo",
        "end": "Fine",
        "home": "Principale",
        "edit": "Modifica",
        "reports": "report",
        "kilometers": "Chilometri",
        "distance": "Distanza",
        "organisation": "Organizzazione",
        "organisations": "Organizzazioni",
        "events": "Eventi",
        "changelog": "Registro delle modifiche",
        "environmental": "Ambientale",
        "no_data": "Nessun dato ricevuto",
        "notes": "Note",
        "update": "Aggiornamento",
        "none": "Nessuno",
        "unknown": "Sconosciuto",
        "not_set": "Non impostato",
        "username": "Nome Utente",
        "password": "Parola D'ordine",
        "fleet": "Flotta",
        "fleet_name": "Nome Flotta",
        "registration": "Registrazione",
        "vehicles": "Veicoli",
        "settings": "Impostazioni",
        "cancel": "Annulla",
        "id": "ID",
        "close": "Chiuso",
        "select_one": "Per favore scegliere uno",
        "email": "Email",
        "phone_number": "Numero di telefono",
        "celcius": "Celsius",
        "coming_soon": "Prossimamente",
        "farenheit": "Fahrenheit",
        "search": "Cerca…",
        "dismiss": "Dismettere",
        "confirm_delete": "Sei sicuro che vuoi cancellare {value}?",
        "name": "Nome",
        "any": "Qualsiasi",
        "delete": "Elimina",
        "language": "Lingua",
        "type": "Tipo",
        "yes": "Si",
        "no": "No",
        "archived": "Archiviato",
        "pressure": "Pressione",
        "cold_pressure": "Pressione a freddo",
        "iccid": "ICCID",
        "emails": "Indirizzo Email1, Indirizzo Email2, ...",
        "grams": "grammi",
        "request_quote": "Richiedi un preventivo",
        "members": "Membri",
        "url": "URL",
        "latitude": "Latitudine",
        "longitude": "Longitudine",
        "timestamp": "Intervallo di tempo",
        "temperature": "Temperatura",
        "no_results": "Nessun risultato trovato",
        "no_results_subheading": "Non è stato possibile trovare risultati corrispondenti ai tuoi criteri di ricerca",
        "refine_search": "Per favore affina la tua ricerca",
        "refine_search_subheading": "Cerca la registrazione di un veicolo o seleziona una flotta/deposito specifico",
        "created_on": "Creato il",
        "created_on_date": "Creato il {data}",
        "registration_id": "Registrazione/ID",
        "position": "Posizione",
        "vehicle": "Veicolo",
        "download": "Download",
        "wheel_position": "Posizione ruote",
        "select_your_option": "Seleziona la tua opzione",
        "are_you_sure": "Sei sicuro?",
        "organization": "Organizazione"
    },
    "organization_settings": {
        "no_profile_picture": "Nessuna immagine del profilo",
        "setup_heading": "Personalizazione avansata organizatione",
        "setup_body": "La personalizzazione avanzata dell'organizzazione, inclusi loghi e personalizzazione dei temi, è disponibile su richiesta a RL Automotive.",
        "successfully_updates": "Impostazioni aggiornate con successo",
        "alerts_subheading": "Veicoli a qui quest {username} riceverà avvisi",
        "no_vehicles_content": "Questo utente non riceverà avvisi da nessun veicolo"
    },
    "organisation-hierachy": {
        "create-organisation": "Crea Organizzazione",
        "create-organisation-modal-subtitle": "Le organizzazioni servono a fornire separazione per clienti e utenti. La creazione di una sotto-organizzazione offre la possibilità di limitare l'accesso ai veicoli."
    },
    "fleet-list": {
        "tyre-status": "Stato dello Pneumatico"
    },
    "fleet_pmi_report": {
        "heading": "Dati di ispezione pre-manutenzione. Il caricamento potrebbe richiedere del tempo",
        "wheel_position": "Posizione ruote",
        "alert_type": "Tipo di allerta",
        "pressure": "Pressione (PSI)",
        "cold_pressure": "Pressione a freddo (PSI)",
        "recommended_pressure": "Pressione raccomandata (PSI)",
        "no_issues": "Nessun problema",
        "no_issues_prompt": "Non sono stati registrati problemi nell'intervallo di tempo selezionato",
        "inflate_difference": "+/- Gonfiaggio (PSI)",
        "total_alerts": "allerte",
        "total_warnings": "avvertimenti",
        "total_maintenance": "consigli per la manutenzione",
        "total_over_inflations": "sovragonfiaggi",
        "total_tyres_checked": "pneumatici controllati",
        "total_vehicles_checked": "veicoli controllati",
        "total_vehicles": "veicoli in totale",
        "percentage_underinflated": "di pneumatici sottogonfiati",
        "percentage_overinflated": "di pneumatici sovragonfiati"
    },
    "fleet_km_report": {
        "heading": "Dati di velocità e distanza. Il caricamento potrebbe richiedere del tempo",
        "total_distance_travelled": "Distanza Totale Percorsa (km)",
        "average_speed": "Velocità Media (km/h)"
    },
    "fleet_carbon_report": {
        "heading": "Dati di emissioni. Il caricamento potrebbe richiedere del tempo",
        "co2_emissions": "Emissioni di CO2 (g/km)",
        "co2_emitted": "CO2 emessa (g)"
    },
    "services": {
        "1": "Latitudine",
        "2": "Longitudine",
        "3": "Altitudine",
        "4": "Pressione Pneumatico 1A",
        "5": "Pressione Pneumatico 1B",
        "6": "Pressione Pneumatico 1C",
        "7": "Pressione Pneumatico 1D",
        "8": "Pressione Pneumatico 2A",
        "9": "Pressione Pneumatico 2B",
        "10": "Pressione Pneumatico 2C",
        "11": "Pressione Pneumatico 2D",
        "12": "Pressione Pneumatico 3A",
        "13": "Pressione Pneumatico 3B",
        "14": "Pressione Pneumatico 3C",
        "15": "Pressione Pneumatico 3D",
        "16": "Pressione Pneumatico 4A",
        "17": "Pressione Pneumatico 4B",
        "18": "Pressione Pneumatico 4C",
        "19": "Pressione Pneumatico 4D",
        "20": "Pressione Pneumatico 5A",
        "21": "Pressione Pneumatico 5B",
        "22": "Pressione Pneumatico 5C",
        "23": "Pressione Pneumatico 5D",
        "24": "Pressione Pneumatico 6A",
        "25": "Pressione Pneumatico 6B",
        "26": "Pressione Pneumatico 6C",
        "27": "Pressione Pneumatico 6D",
        "28": "Odometro",
        "29": "Velocità",
        "30": "Livello di carburante (assoluto)",
        "31": "Avviso di Livello Carburante",
        "32": "Avviso di pastiglie dei freni",
        "33": "Avviso di livello liquido di raffreddamento motore",
        "34": "Avviso di temperatura liquido di raffreddamento motore",
        "35": "Avviso di livello olio motore",
        "36": "Avviso batteria",
        "37": "Temperatura liquido di raffreddamento motore",
        "40": "Livello Carburante",
        "41": "Livello AdBlue",
        "42": "Livello olio motore",
        "46": "Intervallo di manutenzione"
    },
    "vehicle-emissions": {
        "fuel-used": "Carburante usato (Litri)",
        "carbon-emitted": "CO2 emessa (kg)",
        "emissions-since": "Emissione a partire da {0}",
        "distance-travelled": "Distanza percorsa (km)"
    },
    "organisation-base-card": {
        "show-users": "Mostra Utenti",
        "hide-users": "Nascondi Utenti",
        "your-organisation": "La tua Organizzazione",
        "no-login": "Nessun accesso registrato",
        "no-users": "Nessun utente in questa organizzazione",
        "last-seen": "Ultima visualizzazione {0}"
    },
    "organisation_settings": {
        "no_profile_picture": "Nessuna Foto Profilo",
        "setup_heading": "Configurazione avanzata dell'Organizzazione",
        "setup_body": "Su richiesta, RL Automotive fornisce servizi di personalizzazione avanzata dell'organizzazione, tra cui loghi e temi.",
        "successfully_updates": "Profilo aggiornato con successo",
        "alerts_subheading": "Veicolo a cui {username} è iscritto",
        "no_vehicles_content": "Questo utente non è iscritto ad alcun veicolo",
        "view_website": "Visualizzazione sito web"
    },
    "errors": {
        "generic_unexpected_error": "Si è verificato un errore imprevisto",
        "failed_to_load_alert": "Impossibile caricare la pagina dei veicoli, riprova più tardi",
        "failed_to_update_telematics_unit": "Impossibile aggiornare l'unità telematica",
        "failed_to_load_telematics_units": "Impossibile caricare le unità telematiche, riprova più tardi",
        "failed_to_create_telematics_unit": "Impossibile creare l'unità telematica, riprova più tardi",
        "failed_to_delete_telematics_unit": "Impossibile eliminare l'unità telematica, riprova più tardi",
        "update_profile_error": "Si è verificato un errore imprevisto durante l'aggiornamento del profilo",
        "delete_user_error": "Impossibile eliminare l'utente, riprova più tardi"
    },
    "colours": {
        "black": "Nero",
        "blue": "Blu",
        "green": "Verde",
        "yellow": "Giallo",
        "orange": "Arancione",
        "red": "Rosso"
    },
    "pages": {
        "manage_reports": "Gestisci report",
        "manage_sim": "Gestisci Scheda SIM",
        "edit_sim": "Modifica la Scheda SIM"
    },
    "navbar": {
        "kit_menu": "Apri menu kit",
        "user_menu": "Apri menu utente",
        "vehicles": "Veicoli",
        "fleets": "Flotte",
        "sensors": "Sensori",
        "reports": "report",
        "kit": "Kit",
        "sim_cards": "Schede SIM",
        "telematics_boxes": "Telematiche",
        "settings": "Impostazioni dell'account",
        "logout": "Disconnettersi"
    },
    "landing": {
        "quick_actions": "Azioni Rapide",
        "add_vehicle": "Aggiungi Veicolo",
        "add_vehicle_prompt": "Aggiungi un veicolo per iniziare",
        "add_report": "Aggiungi report",
        "add_report_prompt": "Imposta un report per ottenere aggiornamenti sulle tue flotte",
        "dashboard": "Panello",
        "sensors_on_vehicles": "Sensori sui veicoli",
        "sensors_on_vehicles_prompt": "Dei sensori sono assegnati a un veicolo",
        "sensors_off_vehicles": "Sensori non collegati ai veicoli",
        "sensors_off_vehicles_prompt": "I sensori non sono assegnati a un veicolo. Questi sensori non saranno in grado di raccogliere dati",
        "vehicles_prompt": "Veicoli della tua organizzazione e di tutte le organizzazioni che gestisci",
        "latest_changes": "Ultime modifiche"
    },
    "login": {
        "sign_in_message": "Accedi al tuo account",
        "sign_in": "Registrazione",
        "incorrect_username_password": "Nome Utente o Password Errati"
    },
    "vehicle_attributes": {
        "co2_emissions": "Emissioni di CO2(g/km)",
        "type_approval": "Tipo di approvazione",
        "euro_status": "Normativa Euro"
    },
    "manage_vehicles": {
        "registration": "Registrazione",
        "imei": "IMEI",
        "fleet": "Flotta",
        "depot": "Deposito",
        "show_deactivated": "Mostra/nascondi i veicoli disattivati",
        "show_archived": "Mostra/nascondi i veicoli archiviati",
        "add_vehicle": "Aggiungi Veicolo",
        "total_vehicles": "Totale veicoli",
        "details": "Dettagli",
        "no_imei": "Nessun IMEI",
        "alert_level": "Livello di avviso",
        "open_map": "Apri mappa",
        "no_results": "Nessun risultato trovato",
        "no_results_subheading": "Nessun veicolo trovato con i criteri di ricerca selezionati",
        "no_vehicles": "Nessun veicolo",
        "no_vehicles_subheading": "Attualmente non ci sono veicoli nella tua organizzazione. Clicca \"Aggiungi Veicolo\" per crearne uno.",
        "sort_by": "Ordina per",
        "tyre_maintenance": "Manutenzione pneumatico"
    },
    "edit_vehicle": {
        "vehicles": "Veicoli",
        "overview": "Panoramica",
        "logs": "Registri",
        "checklist": "Lista di controllo",
        "mot_history": "Storia MOT"
    },
    "vehicle_overview": {
        "vehicle_alerts": "Email di allerta",
        "speed": "Velocità",
        "engine_capacity": "Cilindrata del motore",
        "revenue_weight": "Peso a pieno carico",
        "vehicle_alerts_description": "Ricevi allerte via email se questo veicolo riscontra un problema.",
        "environmental_breakdown": "Ripartizione del report ambientale",
        "euro_rating_title": "Classificazione Euro non impostata",
        "euro_rating_subtitle": "Per visualizzare questa sezione, imposta la classificazione EURO del veicolo nella configurazione.",
        "add_config_error": "Fornisci a questo veicolo una configurazione per iniziare ad aggiungere sensori",
        "archived": "Archiviato",
        "deactivated": "Disattivato",
        "unarchived": "Non Archiviato",
        "thresholds": "livelli",
        "below_recommended": "al di sotto della livello consigliata",
        "sr_enter_edit_mode": "Accedi alla modalità modifica per i dettagli del veicolo",
        "sr_exit_edit_mode": "Esci dalla modalità modifica per i dettagli del veicolo",
        "type": "Tipo",
        "environmental": "Ambientale",
        "manufacturer": "Produttore",
        "model": "Modello",
        "chassis_type": "Tipo di telaio",
        "fuel_type": "Tipo di carburante",
        "engine_type": "Tipo di motore",
        "mot_due": "Data MOT",
        "fleet": "Flotta",
        "depot": "Deposito",
        "imei": "IMEI",
        "configuration": "Configurazione",
        "application": "Applicazione",
        "emergency_contact": "Contatto di Emergenza",
        "country": "Paese",
        "torque": "Coppia",
        "axle": "Asse",
        "sr_enter_edit_axle_mode": "Entra in modalità modifica per l'asse",
        "sr_exit_edit_axle_mode": "Uscire dalla modalità modifica per l'asse",
        "rim_size": "Dimensione cerchio",
        "tyre_size": "Dimensione pneumatici",
        "minimum_weight": "Peso minimo",
        "maximum_weight": "Peso massimo",
        "recommended_pressure": "Pressione consigliata ({unit})",
        "recommended_temperature": "Temperatura consigliata ({unit})",
        "maintenance_threshold": "livello di manutenzione",
        "maintenance_threshold_description": "Livello che attiverà lo stato di manutenzione",
        "warning_threshold": "Livello di avvertimento",
        "warning_threshold_description": "Livello che attiverà lo stato di avviso",
        "critical_threshold": "Livello critico",
        "critical_threshold_description": "Livello che attiverà lo stato critico",
        "maintenance_level": "Livello di manutenzione ({unit})",
        "warning_level": "Livello di avvertimento ({unit})",
        "danger_level": "Livello di pericolo ({unit})",
        "submit": "Conferma",
        "sensors": "Sensori",
        "assigned_to": "Assegniato a",
        "last_seen": "Ultima Visualizzazione",
        "no_data": "Nessun dato riportato nell'arco temporale selezionato",
        "please_check": "Si prega di controllare i registri dei veicoli per ulteriori informazioni",
        "temp_over_time": "Temperatura/pressione del sensore nel tempo",
        "current_pressure": "Pressione attuale",
        "current_cold_pressure": "Pressione a freddo attuale",
        "current_temp": "Temperatura attuale",
        "time_to_critical": "Tempo in condizione di allerta",
        "recommended_tyre_make": "Produttore di pneumatico consigliato",
        "recommended_tyre_pattern": "Modello di pneumatico consigliato",
        "rim_type": "Tipo di cerchio",
        "tyre_condition": "Condizioni degli pneumatici",
        "new_tread_depth": "Nuova profondità incavi",
        "gps_unavailable": "GPS non disponibile",
        "gps_fix_unavailable": "GPS non disponibile",
        "current_location": "Posizione attuale",
        "no_sensors": "Non ci sono sensori attualmente collegati a questo veicolo",
        "kilometres_travelled": "Chilometri percorsi",
        "kilometres_per_hour": "Chilometri all'ora",
        "kilometers_hour": "chilometri/ora",
        "schematic_view": "Visualizzazione Schematica",
        "list_view": "Visualizzazione ad Elenco",
        "battery_unavailable": "Batteria Non Disponibile",
        "tyre_make": "Modello Veicolo",
        "tyre_pattern": "Modello di pneumatico",
        "estimated_co2": "Emissioni totali stimate di CO2 (kg)",
        "total_emissions": "Emissioni totali",
        "deactivate_vehicle": "Disattiva veicolo",
        "spannered_off": "Modalità manutenzione",
        "on": "on",
        "off": "off"
    },
    "attach_sensor_modal": {
        "checksum_calculator": "Verifica integrità del dato (checksum)",
        "create_title": "Creare un nuovo sensore in posizione",
        "id": "ID",
        "type": "Tipo",
        "create_sensor": "Creare e collegare un sensore",
        "attach_title": "Selezionare un sensore a cui collegarsi",
        "no_unassigned_sensors": "Nessun sensore non assegnato trovato",
        "no_unassigned_subheading": "Rimuovi un sensore da un veicolo o cliccare sul pulsante più per crearne uno nuovo",
        "show_assigned": "Mostra sensori assegnati",
        "attach_sensor": "Collegare Sensori",
        "missing_sensor": "Nessun sensore"
    },
    "delete_sensor_modal": {
        "delete_message": "Questa azione è irreversibile e cancellerà definitivamente questo sensore dalla piattaforma. Se desideri scollegare il sensore per riutilizzarlo, annulla questa operazione e seleziona invece il pulsante di scollegamento."
    },
    "vehicle_logs": {
        "limit": "Limite",
        "sort": "Ordinare",
        "newest": "Piu Recente",
        "oldest": "Piu Vecchio",
        "filter": "Filtro",
        "any": "Qualunque",
        "decode_success": "Decodifica avvenuta con successo",
        "decode_error": "Errore di Decodifica",
        "configuration_error": "Errore di Configurazione",
        "since": "Da",
        "now": "Adesso",
        "reason": "Motivo",
        "sensor_id": "ID Sensore",
        "temperature": "Temperatura",
        "pressure": "Pressione",
        "speed": "Velocità",
        "distance": "Distanza",
        "no_logs": "Nessun registro da visualizzare",
        "no_logs_subheading": "Per favore verificare la configurazione del veicolo",
        "tpm_success_message": "Pacchetto TPM analizzato correttamente",
        "speed_dist_success_message": "Pacchetto velocitàe distanza analizzato correttamente",
        "sensor_data_error_message": "Errore durante la decodifica del pacchetto",
        "lantronix_data_error_message": "Per favore verificare le impostazioni delle unità telematiche",
        "sensor_handshake_message": "Decodificata avvenuta con successo",
        "sensor_error_message": "Qualcosa è andato storto (errore sensore non definito)",
        "unrecognized_external_sensor_pakcet": "Pacchetto sensore esterno non riconosciuto",
        "sensor_flag_set": "Flag sensore impostato, non sono stati ricevuti dati negli ultimi 60 minuti"
    },
    "vehicle_checklist": {
        "checklist": "Lista di controllo",
        "issued_by": "Emesso da",
        "created_at": "Creato a",
        "checklists_not_supported": "Le liste di controllo non sono supportate per questo veicolo",
        "return_to_checklists": "Ritorna alle liste di controllo",
        "return_to_previous_recordings": "Ritorna alla registrazione precedente",
        "add_checklist": "Aggiungi lista di controllo",
        "reset": "Risettare",
        "brakes": "Freni",
        "horn_steering": "Clacson/sterzo",
        "horn_and_steering": "Clacson/sterzo",
        "lights": "Luci",
        "mirror_glass": "Specchio/Vetro",
        "seat_belts": "Cinture di sicurezza",
        "washers_wipers": "Lavavetri/Tergicristalli",
        "battery": "Batteria",
        "fluids_fuel_oil": "Liquidi/Carburante/Olio",
        "bodywork_doors": "Carrozzeria/Porte",
        "bodywork_and_doors": "Carrozzeria/Porte",
        "exhaust": "Scarico",
        "tyres_wheels": "Pneumatici/Ruote",
        "tyres_and_wheels": "Pneumatici/Ruote",
        "load": "Carico",
        "towbar_taillift": "Gancio di traino/Sollevatore posteriore",
        "towbar_or_tail_lift": "Gancio di traino/Sollevatore posteriore",
        "van_check": "Controllo furgone",
        "previous_recordings": "Registrazioni precedenti",
        "faults": "Difetti",
        "no_recordings": "Nessuna precedente registrazione",
        "mirrors_cameras_and_glass": "Specchi, telecamere e vetri",
        "mirrors_and_glass": "Specchi, telecamere e vetri",
        "windscreen_wipers_and_washers": "Tergicristalli e lavavetri",
        "washers_and_wipers": "Tergicristalli e lavavetri",
        "warning_lights": "Luci di avvertimento",
        "steering_and_electronic_stability_control": "Sterzo e controllo elettronico della stabilità",
        "horn": "Clacson",
        "breaks_and_air_build": "Freni e costruzione dell'aria",
        "height_marker": "Indicatore di altezza",
        "seat_belts_and_cab_interior": "Cinture di sicurezza e interno della cabina",
        "security_and_condition_of_cab_doors_steps": "Sicurezza e condizioni delle porte e dei gradini della cabina",
        "brake_lines": "Linee di freno",
        "electrical_connections": "Connessioni elettriche",
        "coupling_security": "Sicurezza di accoppiamento",
        "load_security": "Sicurezza del carico",
        "number_plate": "Targa",
        "reflectors_and_lights": "Riflettori e luci",
        "markers": "Marcatori",
        "ancillary_equipment": "Attrezzatura ausiliaria",
        "lights_indicators_and_side_repeaters": "Luci, indicatori e ripetitori laterali",
        "fuel_oil_leaks": "Perdite di carburante e olio",
        "security_and_condition_of_body_wings": "Sicurezza e condizioni delle parti in movimento del veicolo",
        "battery_security_and_condition": "Sicurezza e condizioni della batteria",
        "diesel_exhaust_fluid_adblue": "Liquido di scarico diesel (AdBlue)",
        "excessive_engine_exhaust_smoke": "Fumo eccessivo allo scarico del motore",
        "aleternative_fuel_systems_and_isolation": "Alternativi sistemi di carburante e isolamento",
        "spray_suppression": "Soppressione degli spruzzi",
        "tyres_and_wheel_fixing": "Fissaggio pneumatici e ruota",
        "high_voltage_emergency_cut_off_switch": "Interruttore di emergenza ad alta tensione",
        "cab_mirrors_seatbelts_glass": "Specchi, cinture di sicurezza e vetri",
        "cab_mirror_seat_belt_and_glass": "Specchi, cinture di sicurezza e vetri",
        "horn_and_gauges": "Clacson e misuratori",
        "turn_signals": "Segnali di svolta",
        "backup_lights_and_alarm": "Luci di retroilluminazione e allarme",
        "fire_extinguisher_condition": "Condizione dell'estintore",
        "windshield_wipers_and_fluid": "Tergicristalli e liquido",
        "fuel_levels": "Livelli di carburante",
        "engine_oil": "Olio motore",
        "transmission_fluid": "Liquido di trasmissione",
        "brake_fluid": "Liquido dei freni",
        "cooling_system_fluid": "Liquido del sistema di raffreddamento",
        "oil_leak_lube": "Perdita di olio / lubrificante",
        "brake_condition": "Condizione dei freni (dinamico, stazionamento, ecc.)",
        "tyres_or_tracks": "Pneumatici o cingoli (gonfiaggio, danni, ecc.)",
        "tires_or_tracks": "Pneumatici o cingoli (gonfiaggio, danni, ecc.)",
        "hydraulic_oil": "Olio idraulico",
        "hose_condition": "Condizione dei tubi di collegamento",
        "coupling_devices_and_connectors": "Dispositivi di accoppiamento e connettori",
        "exhaust_system": "Sistema di scarico",
        "blade_bloom_ripper": "Verifica funzionalità impianti",
        "no_notes": "Non ci sono note per questo veicolo",
        "licence_lamp": "Luci targa",
        "fift_wheel_grease": "Grasso ralla",
        "electrical_connections_and_terminals": "Connessioni e terminali elettrici",
        "dot_reflective_markings": "Marchi riflettenti DOT",
        "access_ramps": "Rampe di accesso",
        "brake_lamps_and_reflectors": "Luci e riflettori dei freni",
        "fasteners": "Elementi di fissagio",
        "ragged_sharp_edges_dammage_body_condition": "Condizione del corpo / danni ai bordi taglienti",
        "trailer_sides_roof_damage": "Lati del rimorchio / danni al tetto",
        "latches_gates_opening_devices": "Chiusure per cancelli / dispositivi di apertura",
        "safety_kits": "Kit di sicurezza",
        "adr_kit": "Kit ADR",
        "fridge_unit": "Unità frigorifera",
        "fridge_unit_fuels": "Carburanti per unità frigorifera",
        "straps": "Cinghie",
        "tailift_condition": "Condizione della sponda idraulica",
        "tailift_operation": "Funzionamento della sponda idraulica",
        "bumpers_guards": "Protezioni paraurti",
        "additional_lamps": "Luci aggiuntive",
        "truck_and_trailer_cables_brake_connections": "Collegamenti del cavo del freno",
        "air_suspension_leaks": "Perdite di sospensione pneumatica",
        "spare_wheel_and_carrier": "Ruota di scorta e portaruota",
        "hooses_and_pipes": "Tubi e tubazioni",
        "tyre_thread_depth": "Profondità del battistrada",
        "tyre_condition": "Condizione del pneumatico",
        "tachograph": "Tachigrafo",
        "adblue_level_ok": "Livello AdBlue OK",
        "steering": " Servo Sterzo",
        "fuel_filter_cap": "Tappo del filtro del carburante",
        "cameras": "Telecamere",
        "load_secure_restraints": "Dispositivi di ritenuta del carico",
        "doors": "Porte",
        "loading_bar": "Barra di caricamento",
        "three_tacho_rolls": "Tre rotoli tachigrafo",
        "sack_truck": "Carrello elevatore",
        "wipes": "Panni",
        "first_aid_kit": "Kit di primo soccorso",
        "fire_extinguisher": "Estintore",
        "breakdown_booklet": "Libretto di guasto",
        "incident_form": "Modulo di incidente",
        "safety_check_sheet": "Scheda di controllo della sicurezza",
        "van_dimensions": "Dimensioni del furgone",
        "no_valuables_on_sight": "Nessun oggetto di valore in vista",
        "fuel_on_return": "Livello carburante al ritorno",
        "fuel_reciept": "Rivevuta del carburante",
        "wheel_nut_indicators": "Indicatori dei dadi delle ruote",
        "wheel_torque_settings": "Impostazioni della coppia delle ruote",
        "attach_image": "Aggiungere Immagine",
        "media": "Media"
    },
    "mot_history": {
        "completed_date": "Complatato il",
        "expiry_date": "Scadenza",
        "odometer_value": "Valore dell'odometro",
        "test_number": "Testo Numero",
        "no_vehicle_found_error": "Non è stato possibile trovare test MOT per questa immatricolazione",
        "test_location": "Test di posizione",
        "major_defects": "Difetti maggiori",
        "first-mot-due": "Data primo MOT",
        "date-registered": "Data di immatricolazione"
    },
    "new_vehicle": {
        "add_vehicle": "Aggiungi Veicolo",
        "basic_information_title": "Informazioni di Base",
        "basic_information_prompt": "Informazioni di base riguardo il veicolo",
        "registration": "Targa",
        "check_dvla": "Controlla DVLA",
        "dvla_duplicate_vehicle": "Esiste già un veicolo con questa immatricolazione",
        "manufacturer": "Produttore",
        "model": "Modello",
        "depot": "Deposito",
        "depots": "Depositi",
        "fleets": "Flotte",
        "missing_sensor": "Senza sensori",
        "sensors": "Sensori",
        "engine_type": "Tipo di Motore",
        "fuel_type": "Tipo di Carburante",
        "map": "Mappa",
        "mot_due": "Data MOT",
        "chassis_type": "Tipo di telaio",
        "vehicle_type": "Tipo di veicolo",
        "imei": "IMEI",
        "application": "Applicazione",
        "torque": "Coppia",
        "sensor_information_title": "Informazioni riguardo i sensori",
        "sensor_information_prompt": "Informazioni rigaurdo quanti sensori e quale tipo",
        "configuration": "Configurazione",
        "sensor_manifest": "Modello del sensore",
        "create_without_sensors": "Crea senza sensori",
        "create_with_sensors": "Crea con i sensori",
        "list": "Veicoli"
    },
    "vehicle_download": {
        "pressure": "Pressione pneumatico",
        "cold_pressure": "Pressione a freddo pneumatico",
        "temperature": "Temperatura pneumatico",
        "select_all": "Seleziona tutto",
        "deselect_all": "Deseleziona tutto",
        "subheading": "Specifica i parametri e l'intervallo di tempo entro cui desideri scaricare i dati. I download potrebbero richiedere un po' di tempo, puoi uscire da questa pagina una volta avviato il download e il file apparirà nella tua cartella download una volta completato."
    },
    "manage_fleets": {
        "add_fleet_contact": "Aggiungi contatto a {fleet}",
        "contact_name": "Nome contatto",
        "add_contact": "Aggiungere Contatto",
        "add_fleet": "Aggiungere Flotta",
        "total_fleets": "Flotte totali",
        "defaults": "Predefinito",
        "defaults_prompt": "Questi valori verranno utilizzati come livelli predefinite per tutti i nuovi veicoli creati in questa flotta.",
        "create_depot_prompt": "Aggiungere un depositito a",
        "depots": "{ count } Deposito | { count } Depositi",
        "created_on": "Creato al { date }",
        "add_depot": "Aggiungere deposito",
        "no_fleets": "Nessuna flotta",
        "no_depots": "Nessun deposito",
        "no_fleets_prompt": "Al momento non hai flotte nella tua organizzazione. Clicca su \"Aggiungi flotta\" per crearne una.",
        "no_depots_prompt": "Questa flotta non contiene alcun deposito. Cliccare sul pulsante \"Aggiungi deposito\" per crearne uno.",
        "duplicate_fleet_error": "Una flotta con quel nome esiste già",
        "duplicate_depot_error": "Un deposito con quel nome esiste già",
        "confirm_delete_depot_modal_text": "Sei sicuro che vuoi eliminare {depot}?",
        "confirm_delete_fleet_modal_text": "Sei sicuro che vuoi cancellare {fleet}?",
        "sensors_prompt": "Ai seguenti veicoli manca uno o più sensori",
        "empty_prompt_header": "Tutti i veicoli sono correttamente configurati",
        "empty_prompt_subtitle": "Non ci sono veicoli in questa flotta con sensori mancanti",
        "subscribe": "Iscriviti",
        "unsubscribe": "Disiscriviti",
        "subscribe_prompt": "Inscriviti a tutti i veicoli in questa flotta",
        "unsubscribe_prompt": "Annulla l'iscrizione a tutti i veicoli in questa flotta",
        "colour": "Colore",
        "definition": "Definizione",
        "black_definition": "Nessun dato",
        "blue_definition": "Nessun dato nelle ultime 12 ore",
        "green_definition": "Nessun problema",
        "yellow_definition": "Sotto il livello di manutenzione",
        "orange_definition": "Sotto il livello di avvertimento",
        "red_definition": "Sottoil livello critico",
        "subscribe_failed": "Impossibile iscriversi a questa flotta",
        "subscribe_success": "Iscritto con successo a tutti i veicoli in questa flotta",
        "unsubscribe_failed": "Impossibile annullare l'iscrizione a questa flotta",
        "unsubscribe_success": "Annullata l'iscrizione a tutti i veicoli in questa flotta",
        "delete_fleet": "Cancella Flotta",
        "delete_failed": "Impossibile eliminare la flotta",
        "update_depot": "Aggiorna deposito",
        "show_map": "Mostra mappa",
        "hide_map": "Nascondi mappa"
    },
    "sensors": {
        "add_sensor": "Aggiungi Sensore",
        "sensor_type": "Tipo di sensore",
        "bound": "Limite",
        "wheel_position": "Posizione ruota",
        "on_vehicle": "In Veicolo",
        "not_on_vehicle": "Non sul veicolo",
        "no_vehicle": "Nessun Veicolo",
        "updated_on": "Aggiornato il",
        "no_wheel": "Nessuna ruota",
        "same_id_error": "Un sensore con lo stesso ID già presente",
        "no_reg_error": "Non è stato possibile trovare nessun veicolo con questa targa",
        "axle_number": "Numero Asse",
        "wheel_number": "Numero ruota",
        "no_data_recorded": "Nessun dato registrato",
        "failed_to_load": "Impossibile caricare i sensori",
        "no_sensors": "Nessun sensore",
        "no_sensors_subheading": "Al momento non hai sensori nella tua organizzazione. Cliccare su \"Aggiungi sensore\" per crearne uno.",
        "no_results_subheading": "Non è stato possibile trovare sensori che corrispondano ai tuoi criteri di ricerca",
        "tyre_size": "Dimensione pneumatico",
        "tyre_make": "Marca di pneumatici",
        "tyre_pattern": "Modello di pneumatico"
    },
    "reports": {
        "add_report": "Aggiungi Report",
        "reports": "report",
        "add report": "aggiungi report",
        "schedule": "Programma",
        "send_now": "Invia ora",
        "edit_report": "Modifica report",
        "emails": "Indirizzi Email",
        "language": "Lingua",
        "ima": "Ispezione Pre-Manutenzione (PMI)",
        "km": "Chilometragio (KM)",
        "co2": "Emissioni (CO2)",
        "maintenance": "Manutenzione",
        "vehicle_list": "Elenco veicoli",
        "basic_information": "Informazioni di Base",
        "basic_information_prompt": "Seleziona il tipo di report che desideri creare e inserisci i dettagli richiesti",
        "frequency": "Frequenza",
        "frequency_prompt": "Seleziona la frequenza con cui desideri inviare il report",
        "day": "Giorno",
        "daily": "Giornaliero",
        "hour": "Ora",
        "failed_to_load": "Impossibile caricare il report. Riprova più tardi",
        "error_sending": "si è verificato un errore durante l'invio del report",
        "confirmation_send": "Report inviato con successo",
        "delete_error": "si è verificato un errore durante l'eliminazione del report",
        "no_reports": "Nessun report",
        "no_reports_subheading": "Al momento non hai report nella tua organizzazione. Clicca su \"Aggiungi report\" per crearne uno"
    },
    "sim": {
        "add_sim": "Aggiungi scheda SIM",
        "search_iccid": "Cerca ICCID",
        "iccid_prompt": "L'identificativo univoco per la scheda SIM",
        "msisdn_prompt": "Il numero di telefono di 10-14 cifre compreso il prefisso internazionale (ad es. 44 nel Regno Unito)",
        "provider": "Fornitore",
        "send_sms": "Invia SMS",
        "send_sms_prompt": "Invia SMS a",
        "message": "Messagio",
        "sms_sent": "Messaggio inviato con successo",
        "imsi_description": "Numero ISDN della telematica.",
        "provider_description": "Fornitore che ha emesso la scheda SIM. Se il tuo fornitore SIM non è elencato, contatta l'assistenza.",
        "unable_to_delete": "Impossibile eliminare la scheda SIM, contattare l'assistenza.",
        "unable_to_edit": "Impossibile modificare la scheda SIM, contattare l'assistenza.",
        "failed_to_create": "Impossibile creare la scheda SIM, controllare la configurazione e riprovare",
        "no_sim_cards": "Nessuna scheda SIM",
        "no_sim_cards_subheading": "Al momento non hai schede SIM nella tua organizzazione. Clicca su \"Aggiungi scheda SIM\" per crearne una"
    },
    "telematics": {
        "edit_telematics": "Modifica unità telematica",
        "no_update_information": "Nessuna aggiornamento",
        "add_telematics": "Aggiungere Telematica",
        "search_imei": "Cerca IMEI",
        "go-to-vehicle": "Vai al veicolo",
        "software": "Software",
        "hardware": "Hardware",
        "main_port": "Porta Principale",
        "accessory_port": "Porta accessoria",
        "device_name": "Nome Dispositivo",
        "remove": "Rimuovi unità telematica",
        "failed_to_load": "Impossibile caricare l'unità telematica",
        "tyre-control-box-id": "Receiver ID",
        "no_telematics_boxes": "Nessuna Telematica",
        "process-from-configurator": "Processo dal Configuratore",
        "no_telematics_boxes_subheading": "Al momento non hai caselle telematiche nella tua organizzazione. Cliccare su \"Aggiungi casella telematica\" per crearne una",
        "online": "Online",
        "offline": "Offline",
        "move-telematics-unit": "Sposta unità telematica"
    },
    "settings": {
        "profile": "Profilo",
        "old_password": "Vecchia Password",
        "new_password": "Nuova Password",
        "confirm_password": "Conferma Password",
        "update_password": "Aggiornare la Password",
        "profile_prompt": "Informazioni di base relative al tuo account.",
        "password_prompt": "Cambia password.",
        "first_name": "Nome",
        "last_name": "Cognome",
        "password": "Password",
        "locale": "Locale",
        "locale_prompt": "Cambia il modo in cui le informazioni vengono visualizzate sul sito web. La lingua e le unità di temperatura selezionate qui verranno riflesse anche nei report, ad esempio nei report IMA.",
        "language": "Lingua",
        "pressure_unit": "Unita di Pressione",
        "temperature_unit": "Unita di Temperatura",
        "time_zone": "Fuso Orario",
        "coming_soon": "(prossimamente)",
        "update_settings": "Aggiornare le Impostazioni",
        "alerts": "Avvisi",
        "no_alerts": "Nessun avviso",
        "alerts_subtitle": "Riceverai avvisi da questi veicoli.",
        "organisation_subtitle": "Impostazioni e informazioni relative alla tua organizzazione.",
        "no_active_subscriptions_placeholder": "Non hai iscrizioni attivi. Le iscrizioni possono essere attivatie nel menu del veicolo.",
        "add_user": "Aggiungi Utente",
        "username_error": "Quel nome utente o email è già stato preso",
        "email_error": "Si prega di inserire un indirizzo email valido",
        "current_organisation": "Corrente (predefinito)",
        "confirm_delete_user_modal_text": "Sei sicuro che vuoi cancellare questo user {user}?"
    },
    "not_found_page": {
        "title": "Pagina non trovata",
        "subtitle": "Controlla l'URL nella barra degli indirizzi e riprova.",
        "return_home": "Torna alla pagina iniziale"
    },
    "server_error_page": {
        "title": "Errore interno del server",
        "subtitle": "Il server ha riscontrato un errore e non è riuscito a completare la richiesta.",
        "return_home": "Torna alla pagina iniziale"
    },
    "application": {
        "logistics": "Logistica",
        "mixed_service": "Servizio Misto",
        "waste": "Rifiuti",
        "food_distribution": "Distribuzione di cibo",
        "food_waste": "Rifiuti alimentari",
        "psv": "PSV",
        "off_highway": "Fuori Strada",
        "urban_last_mile": "Ultimo  / Miglio Urbano",
        "other": "Altro"
    },
    "fuel": {
        "petrol": "BENZINA",
        "diesel": "DIESEL",
        "electric": "ELETTRICO",
        "hybrid": "IBRIDO",
        "biofuel": "BIOCARBURANTE",
        "hydrogen_fuel_cell": "CELLA A COMBUSTIBILE A IDROGENO",
        "compressed_natural_gas": "GAS NATURALE COMPRESSO",
        "liquified_petroleum_gas": "GAS PETROLIFERO LIQUEFATTO",
        "non_applicable": "None"
    },
    "vehicle_type": {
        "bus": "Bus",
        "car": "Auto",
        "crane": "Gru",
        "digger": "Escavatore",
        "dumper": "Dumper",
        "loader": "Caricatore",
        "tractor": "Trattore",
        "trailer": "Rimorchio",
        "truck": "Camion",
        "truck_trailer": "Camion Rimorchio",
        "tug": "Veicolo rimorchiatore",
        "van": "Furgone"
    },
    "tyre_condition": {
        "new": "NUOVO",
        "re-mould": "RICOSTRUITO",
        "re-grooved": "RIFRETTATO"
    },
    "days": {
        "monday": "Lunedì",
        "tuesday": "Martedì",
        "wednesday": "Mercoledì",
        "thursday": "Giovedì",
        "friday": "Venerdì",
        "saturday": "Sabato",
        "sunday": "Domenica"
    },
    "dvla": {
        "no_registration": "Inserisci una registrazione per controllare DVLA",
        "failed_to_create": "Impossibile creare il veicolo",
        "not_found_error": "Veicolo non trovato"
    },
    "abbreviations": {
        "kilobytes": "KB"
    },
    "footer": {
        "powered_by_text": "Fornito da RL Automotive"
    },
    "telematics-unit-vehicle-create-modal": {
        "imei-subtitle": "IMEI devono contenere solo numeri",
        "no-sim-card": "Nessuna scheda SIM",
        "create-sim-card-subtitle": "Se non riesci a trovare la scheda SIM elencata di seguito, potrebbe essere necessario crearne una",
        "no-matching-search": "Nessuna scheda SIM che corrisponde a quel criterio di ricerca",
        "search-results-hidden": "Ulteriori risultati di ricerca nascosti"
    },
    "app-error-modal": {
        "extra-information": "Informazioni aggiuntive"
    }
}
