import { defineStore } from "pinia";

export const useFleetStore = defineStore({
    id: "fleet",
    state: () => ({
        _fleets: [],
    }),
    actions: {
        async getFleets() {
            // get a list of fleets from the cahce
            // if possible, else query the fleets
            // from the API.
            if (this._fleets.length) {
                return this._fleets;
            }
            try {
                const fleets = await this._getFleets();
                if (fleets) {
                    this._fleets = fleets;
                } else {
                    this._invalidateCache();
                }
            } catch (error) {
                this._invalidateCache();
            }
            return this._fleets;
        },

        getFleetByName(name) {
            // O(n) lookup for a fleet by name
            // could be done with a HashMap instead
            // but this isn't a speed bottleneck for now.
            for (const fleet of this._fleets) {
                if (fleet.name === name) {
                    return fleet;
                }
            }
            return null;
        },

        async _getFleets() {
            const response = await this.$axios.get("tyrewatch/fleets/");
            return response.data;
        },

        async updateFleet(prevName, newName) {
            if (!prevName || !newName) {
                throw "Fleet name cannot be empty";
            }
            const prevFleet = this.getFleetByName(prevName);
            if (prevFleet === null) {
                throw `There is no fleet named ${prevName}`;
            }
            const newFleet = this.getFleetByName(newName);
            if (newFleet !== null) {
                throw `There is already a fleet named ${newName}`;
            }

            await this.$axios.put(`/tyrewatch/fleets/${prevName}`, {
                new_fleet_name: newName,
                // TODO: allow updating the fleet's
                // thresholds.
                default_maintenance_threshold: 0,
                default_warning_threshold: 0,
                default_danger_threshold: 0,
            });
            this._invalidateCache();
        },

        async createFleet(fleetName, organization, maintenance = 20, warning = 27.5, danger = 30) {
            if (!fleetName) {
                throw "Fleet name cannot be empty";
            }

            const fleet = this.getFleetByName(fleetName);
            if (fleet !== null) {
                throw "There is already a fleet with that name";
            }

            await this.$axios.post(`/tyrewatch/fleets/${fleetName}`, {
                organization_name: organization,
                default_maintenance_threshold: maintenance,
                default_warning_threshold: warning,
                default_danger_threshold: danger,
            });
            this._invalidateCache();
        },

        async deleteFleet(fleetName) {
            if (!fleetName) {
                throw "Fleet name cannot be empty";
            }
            const fleet = this.getFleetByName(fleetName);
            if (fleet === null) {
                throw `There is no fleet name ${fleetName}`;
            }
            if (fleet.depots.length > 0) {
                throw "Cannot delete a fleet with depots";
            }

            await this.$axios.delete(`/tyrewatch/fleets/${fleetName}`);
            this._invalidateCache();
        },

        flush() {
            this._invalidateCache();
        },

        _invalidateCache() {
            this._fleets = [];
        },
    },
});
