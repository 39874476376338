import { defineStore } from "pinia";
import { useFleetStore } from "./fleets.js";

export const useStore = defineStore({
    id: "authentication",
    state: () => ({
        user: JSON.parse(localStorage.getItem("user") || null),
        returnUrl: null,
        fleetStore: useFleetStore(),
    }),
    actions: {
        async login(username, password) {
            const params = new URLSearchParams();
            params.append("username", username);
            params.append("password", password);

            try {
                const response = await this.$axios.post("/tyrewatch/auth/token", params);

                const user = response.data;
                localStorage.setItem("user", JSON.stringify(user));
                this.user = user;
                this.$axios.defaults.headers.common["Authorization"] = `Bearer ${user.access_token}`;
                return true;
            } catch (error) {
                return false;
            }
        },
        async refresh() {
            try {
                const params = new URLSearchParams();
                params.append("refresh_token", this.user.refresh_token);
                params.append("grant_type", "refresh_token");
                const { data: user } = await this.$axios.post("/tyrewatch/auth/refresh", params);
                localStorage.setItem("user", JSON.stringify(user));
                this.user = user;
                this.$axios.defaults.headers.common["Authorization"] = `Bearer ${user.access_token}`;
                return user;
            } catch (error) {
                this.logout();
                return null;
            }
        },
        logout() {
            this.user = null;
            // clear the entire contents of localStorage.
            // This will ensure all caches are cleared to prevent
            // seeing old content on re-log.
            localStorage.clear();
            this.fleetStore.flush();
            this.$axios.defaults.headers.common["Authorization"] = null;
        },
        loggedIn() {
            return this.user !== null;
        },
    },
    getters: {
        username: (state) => {
            const token = state.user?.access_token;
            if (token === null || token === undefined) {
                return null;
            }
            const base64Url = token.split(".")[1];
            const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            var jsonPayload = decodeURIComponent(
                window
                    .atob(base64)
                    .split("")
                    .map(function (c) {
                        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join("")
            );

            return JSON.parse(jsonPayload);
        },

        isAdmin: (state) => {
            if (state.user) {
                return state.user.role === "ADMIN";
            }
            return false;
        },
    },
});
